<template>
  <div class="c-app modal-vue miner-info">
    <CWrapper class="bg-bg-gray pt-2">
      <div class="breadcrumb">
        <router-link to="/Miners/1">Miners</router-link>/ MinersInfo
      </div>
      <div class="d-flex flex-wrap justify-content-between align-items-center mb-40">
        <div>
          <div class="c-title">{{ minerInfo.name }}</div>
          <div class="text-gray text-lg">{{ $t("Miner's Information") }}</div>
        </div>
        <div class="d-flex mobile-flex-wrap mt-4 mt-lg-0 mobile-100">
          <CButton
            @click="getLogs"
            class="button-blue mobile-100 text-uppercase px-50 rounded"
            >{{ $t("Logs") }}</CButton
          >
          <CButton
            class="mt-3 mt-lg-0 button-white text-uppercase px-50 mobile-100 ml-50 rounded"
            @click="reloadPage"
            >{{ $t("Reload") }}</CButton
          >
        </div>
      </div>
      <CRow class="mb-40">
        <CCol lg="6" md="12">
          <table class="table">
            <thead>
              <th>{{ $t("Miner Information") }}</th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t("MAC-address") }}:</td>
                <td>{{ minerInfo.mac_address }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("IP") }}:</td>
                <td>{{ minerInfo.ip_address }}</td>
                <td>
                  <CButton @click="editFunction('ip_address')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Model") }}:</td>
                <td>{{ minerInfo.model }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Binding to Customer") }}:</td>
                <td>{{ minerInfo.name }}</td>
                <td></td>
              </tr>
              <!-- <tr>
                <td>{{ $t("Binding to Pool") }}:</td>
                <td>{{ minerInfo.pools }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Worker") }}:</td>
                <td>{{ minerInfo.worker_id }}</td>
                <td></td>
              </tr> -->
              <tr>
                <td>{{ $t("Farm") }}:</td>
                <td>{{ minerInfo.farm_name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Container") }}:</td>
                <td>{{ minerInfo.container_name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Section") }}:</td>
                <td>{{ minerInfo.section }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Position") }}:</td>
                <td>{{ minerInfo.position }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Miners name") }}:</td>
                <td>{{ minerInfo.name }}</td>
                <td>
                  <CButton @click="editFunction('name')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Crawler") }}:</td>
                <td>{{ minerInfo.crawler }}</td>
                <td>
                  <CButton @click="editFunction('crawler')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Expected Hashrate") }}:</td>
                <td>{{ convertHashrate(minerInfo.exp_hashrate) }}</td>
                <td>
                  <CButton @click="editFunction('exp_hashrate')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Current Hashrate") }}:</td>
                <td>{{ convertHashrate(minerInfo.crt_hashrate) }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Expected Temperature") }}:</td>
                <td>{{ minerInfo.exp_temperature }} C</td>
                <td>
                  <CButton @click="editFunction('exp_temperature')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Current Temperature") }}:</td>
                <td>{{ minerInfo.crt_temperature }} C</td>
                <td></td>
              </tr>
              <tr>
                <td>Exp. Power Consumption:</td>
                <td>{{ minerInfo.exp_powerrate }} Kw*H</td>
                <td>
                  <CButton @click="editFunction('exp_powerrate')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Effeciency") }}:</td>
                <td>{{ minerInfo.efficiency }} %</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Rejected rate") }}:</td>
                <td>{{ minerInfo.rejected_rate }}%</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Accepted rate") }}:</td>
                <td>{{ minerInfo.accepted_rate }}%</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Working time") }}:</td>
                <td>{{ macAddresses ? macAddresses.elapsed : "" }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Number of errors") }}:</td>
                <td>
                  {{ minerInfo.errors ? minerInfo.errors.split(",").length : 0 }}
                  in 24h
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Number of network errors") }}:</td>
                <td>
                  {{ countError }}
                  in 24h
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Fan rotation rates") }}:</td>
                <td>{{ macAddresses ? macAddresses.fan : "" }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Date added") }}:</td>
                <td>{{ minerInfo.updated_at }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Date updated") }}:</td>
                <td>
                  {{
                    new Date().toISOString().split("T")[0] +
                    " " +
                    new Date().toLocaleTimeString()
                  }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("Status") }}:</td>
                <td>
                  <span
                    v-if="userInfo && userInfo.user.role != 'admin'"
                    :class="
                      minerInfo.status == 'inactive' ? 'miners-danger' : 'miners-well'
                    "
                  >
                    {{ minerInfo.status | statusChange }}
                  </span>
                  <span
                    :class="
                      minerInfo.status == 'inactive' ? 'miners-danger' : 'miners-well'
                    "
                    v-else
                  >
                    {{ minerInfo.status }}
                  </span>
                </td>
                <td>
                  <CButton @click="editFunction('status')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Description") }}:</td>
                <td>{{ minerInfo.description }}</td>
                <td>
                  <CButton @click="editFunction('description')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-4 mb-4 mb-lg-0">
            <div class="text-main_black mb-1">{{ $t("Errors") }}:</div>
            <CListGroup v-if="minerInfo.errors">
              <CListGroupItem v-for="(obj, i) in minerInfo.errors.split(',')" :key="i">
                {{ obj }}
              </CListGroupItem>
            </CListGroup>
            <CListGroup v-else>
              <CListGroupItem> {{ $t("No") }} {{ $t("Errors") }} </CListGroupItem>
            </CListGroup>
          </div>
        </CCol>
        <CCol lg="6" md="12">
          <CForm class="add-miners__wrap py-0">
            <CChartLine
              class="bg-white"
              :datasets="[
                {
                  data: getMinerReportHashrates.map((res) => res.current_hashrate),
                  borderColor: '#EF3B50',
                  backgroundColor: 'transparent',
                  label: 'Current Hashrate',
                },
                {
                  data: getMinerReportHashrates.map((res) => res.expected_hashrate),
                  borderColor: '#04B77E',
                  backgroundColor: 'transparent',
                  label: 'Expected Hashrate',
                },
              ]"
              :labels="getMinerReportHashrates.map((res) => res.hour)"
              :options="getCustomChart('hour', 'Gh/s')"
            />
            <CChartLine
              class="bg-white my-3"
              :datasets="[
                {
                  data: getMinerReportHashrates.map((res) => res.current_temperature),
                  borderColor: '#EF3B50',
                  backgroundColor: 'transparent',
                  label: 'Current Temperature',
                },
                {
                  data: getMinerReportHashrates.map((res) => res.expected_temperature),
                  borderColor: '#04B77E',
                  backgroundColor: 'transparent',
                  label: 'Expected Temperature',
                },
              ]"
              :labels="getMinerReportHashrates.map((res) => res.hour)"
              :options="getCustomChart('hour', 'C, t')"
            />
            <CSelect
              :label="i18n.t('Owner')"
              name="user_id"
              :options="
                allCustomers &&
                allCustomers.map((res) => {
                  return { ...res, value: res ? res.email : '' };
                })
              "
              :value="
                allCustomers.length > 0 && minerInfo.user_id
                  ? allCustomers.filter((obj) => obj.id == minerInfo.user_id)[0].email
                  : ''
              "
              @update:value="updateCustomerValues"
            />

            <div class="mb-4">
              <label>{{ $t("Pool") }}</label>
              <CButton
                @click="getPoolsModal"
                class="d-block button-white mt-3 mt-lg-0 text-uppercase mobile-100 px-50 w-100 rounded w-auto"
                >{{ $t("Show pools") }}</CButton
              >
            </div>
            <div>
              <label for="settings">{{ $t("Settings") }}</label>
              <textarea id="settings" class="d-block"> {{ minerInfo.setting }} </textarea>
            </div>
          </CForm>
        </CCol>
        <CCol md="12" class="mt-4">
          <div class="text-main_black mb-1">{{ $t("Comments") }}</div>
          <table class="table second-table">
            <thead>
              <th>№</th>
              <th>{{ $t("Message") }}</th>
              <th>{{ $t("Date") }}</th>
            </thead>
            <tbody>
              <tr v-if="minerComments.length === 0">
                <td>{{ $t("No") }} {{ $t("Comments") }}</td>
              </tr>
              <tr v-else v-for="(obj, i) in minerComments" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ obj.text }}
                </td>
                <td>{{ obj.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol md="12" class="mt-4 mb-4">
          <div class="text-main_black mb-1">{{ $t("Miner Changes Log") }}</div>
          <table class="table second-table">
            <thead>
              <th>№</th>
              <th>{{ $t("Field") }}</th>
              <th>{{ $t("Old value") }}</th>
              <th>{{ $t("New value") }}</th>
              <th>{{ $t("User") }}</th>
              <th>{{ $t("Created date") }}</th>
            </thead>
            <tbody>
              <tr v-if="minerLogs.length === 0">
                <td>{{ $t("No") }} {{ $t("Logs") }}</td>
              </tr>
              <tr v-for="(obj, i) in minerLogs" :key="i">
                <td>{{ i + 1 }}</td>
                <td style="width: 20%">
                  {{ obj.field }}
                </td>
                <td style="width: 20%">{{ obj.oldvalue }}</td>
                <td style="width: 20%">{{ obj.newvalue }}</td>
                <td style="width: 20%">{{ obj.user }}</td>
                <td style="width: 30%">
                  {{ new Date(obj.created_at) | customFormatter }}
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol md="12" class="mt-4 mb-4">
          <div>
            <label for="settings" class="text-main_black">{{
              $t("Add new comment")
            }}</label>
            <textarea
              id="settings"
              class="d-block"
              style="height: 130px"
              @change="changeField"
            >
            </textarea>
          </div>
        </CCol>
        <CCol md="12" class="d-flex mobile-flex-wrap justify-content-end w-100">
          <CButton
            class="button-white text-uppercase mobile-100 px-50 rounded w-auto text-gray border-gray"
            style="box-shadow: 0px 0px 16px rgba(152, 194, 58, 0.16)"
            >{{ $t("cancel") }}</CButton
          >
          <CButton
            class="button-blue mt-3 mt-lg-0 text-uppercase mobile-100 px-50 ml-50 rounded w-auto"
            style="box-shadow: 0px 8px 8px rgba(100, 104, 106, 0.08)"
            @click="submitComment"
            >{{ $t("submit") }}</CButton
          >
        </CCol>
      </CRow>
    </CWrapper>
    <CModal title="Modal title" color="lightGray" :show.sync="warningModal">
      <label for="first_name">{{ modalObj.title }}</label>
      <CInput
        class=""
        :id="modalObj.id"
        :value.sync="modalObj.value"
        v-if="modalObj.title != 'status'"
      />
      <CSelect
        v-else
        @update:value="updateStatusMiner"
        :options="[
          'pool_changed',
          'active',
          'inactive',
          'modified',
          'under_repair',
          'disable',
        ]"
        :value="modalObj.value"
      />
      <div class="text-right">
        <CButton
          @click="
            modalObj.title == 'status'
              ? updateMinerInfoWithStatus(modalObj)
              : updateMiner(modalObj.id, modalObj)
          "
          class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        >
          Update
        </CButton>
      </div>
      <template #footer="{ item }" class="d-none p-0">
        <span class="d-none" />
      </template>
    </CModal>
    <CModal title="Logs" color="lightGray" :show.sync="warningModal2">
      <textarea name="" id="" cols="30" rows="10" v-model="logs"> </textarea>
    </CModal>
    <CModal
      class="pools-modal"
      title="Pools"
      color="lightGray"
      :show.sync="warningModal3"
    >
      <div>
        <p>Current value</p>
        <table class="table">
          <thead>
            <th>№</th>
            <th>Pool</th>
            <th>Worker</th>
          </thead>
          <tbody v-if="minerInfo.pools ? Array.isArray(JSON.parse(minerInfo.pools)) : ''">
            <tr v-for="(obj, i) in JSON.parse(minerInfo.pools)" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ obj.url }}</td>
              <td>{{ obj.user }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3" v-if="minerInfo.status == 'pool_changed'">
        <p>New value</p>
        <table class="table">
          <thead>
            <th>№</th>
            <th>Pool</th>
            <th>Worker</th>
          </thead>
          <tbody v-if="minerPoolInfo ? Array.isArray(minerPoolInfo) : ''">
            <tr v-for="(obj, i) in minerPoolInfo" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ obj.url }}</td>
              <td>{{ obj.user }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <template #footer="{ item }" class="d-none p-0">
        <CButton
          @click="warningModal3 = false"
          class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        >
          cancel
        </CButton>
        <CButton
          v-if="
            userInfo &&
            userInfo.user.role != 'customer' &&
            minerInfo.status == 'pool_changed'
          "
          @click="changeCurrentPools()"
          class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        >
          Update
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import i18n from "../../i18n";
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
import { storeParent } from "../../api/composition/filtering";
import { computed, ref } from "@vue/composition-api";

export default {
  name: "MinerInfo",
  components: {},
  setup(props, { root }) {
    const _store = storeParent(root);
    const _dispatch = _store.dispatchComposition();
    const warningModal = ref(false);

    const getters = computed(() => _store.gettersComposition());

    const updateMiner = (id, obj) => {
      _dispatch.updateMiner(id, {
        ...getters.value.minerInfo,
        [obj.title]: obj.value,
      });
      warningModal.value = false;
    };

    return { updateMiner, warningModal };
  },
  filters: {
    statusChange: function (value) {
      if (value === "inactive") return "inactive";
      return "active";
    },
    customFormatter(date) {
      // custom formatter
      return moment(date).format("DD/MM/YYYY");
    },
  },
  data() {
    return {
      page: parseInt(this.$route.params.id),
      i18n,
      minerComments: [],
      minerLogs: [],
      formFields: {
        user_id: -1,
        miner_id: this.$route.params.id,
        text: "",
      },
      warningModal2: false,
      warningModal3: false,
      userForms: {},
      minerPoolInfo: [],
      countError: "",
      modalObj: { title: "", id: 0, value: "" },
      optionsChart: {
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters("miners", ["getMinerReportHashrates"]),
    ...mapState("miners", ["minerInfo", "macAddresses", "logs", "allCustomers"]),
    ...mapState("user", ["userInfo"]),
  },
  created() {
    this.curPage();
    this.$store.dispatch("miners/GET_MINER_INFO", this.$route.params.id).then((res) => {
      this.getMacAddress(res.data ? res.data : {});
      this.minerPoolInfo = res.pool ? JSON.parse(res.pool.pools) : [];
      this.minerLogs = res.logs;
      this.minerComments = res.comments;
    });
    this.$store.dispatch("miners/MINER_REPORT", this.$route.params.id);
    this.$store.dispatch("miners/GET_ALL_CUSTOMERS");
    this.GET_COUNT_ERROR(this.$route.params.id).then((res) => {
      this.countError = res.data;
    });
  },
  methods: {
    ...mapActions("miners", [
      "ADD_COMMENT",
      "GET_LOGS",
      "GET_MACADDRESS",
      "UPDATE_MINER",
      "CHANGE_MINER_STATUS",
      "GET_COUNT_ERROR",
    ]),
    changeCurrentPools() {
      this.CHANGE_MINER_STATUS({
        id: this.page,
        body: {
          targetPool: JSON.stringify(this.minerPoolInfo),
          status: "active",
        },
      }).then((res) => {
        // console.log(res);
      });
    },
    curPage() {
      this.$emit("curPage", "MinerInfo");
    },
    updateStatusMiner(value, e) {
      if (value == "inactive") {
        console.log(this.formFields);
        this.modalObj = {
          ...this.modalObj,
          ip_address: this.minerInfo.ip_address + Math.random().toString(16).slice(2),
          status: value,
        };
      }
      this.modalObj = {
        ...this.modalObj,
        status: value,
      };
    },
    updateCustomerValues(value, e) {
      this.userForms = {
        ...this.userForms,
        user_id: this.allCustomers[e.target.selectedOptions[0].dataset.key].id,
      };
      this.UPDATE_MINER({
        id: this.minerInfo.id,
        body: { ...this.minerInfo, ...this.userForms },
      });
    },
    updateMinerInfoWithStatus(obj) {
      this.UPDATE_MINER({
        id: this.minerInfo.id,
        body: { ...this.minerInfo, ...obj },
      }).then(() => {
        this.warningModal = false;
      });
    },
    getLogs() {
      this.warningModal2 = true;
      this.GET_LOGS(this.minerInfo);
    },
    getPoolsModal() {
      this.warningModal3 = true;
    },
    getMacAddress(r) {
      this.GET_MACADDRESS(r);
    },
    reloadPage() {
      window.location.reload();
    },

    editFunction(e) {
      this.modalObj = {
        title: e,
        value: this.minerInfo[e],
        id: this.minerInfo.id,
      };
      this.warningModal = true;
    },
    submitComment() {
      this.formFields = {
        ...this.formFields,
        user_id: this.$store.state.user.userInfo.id,
      };

      if (this.formFields.text === "" || this.formFields.user_id === -1) return;

      this.ADD_COMMENT({
        id: this.$route.params.id,
        body: { ...this.formFields },
      }).then(() => {
        this.minerComments = [...this.minerComments, this.formFields];
      });
    },
    changeField(e) {
      this.formFields = {
        ...this.formFields,
        text: e.target.value,
      };
    },
    getCustomChart(xName, yName) {
      return {
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: xName,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: yName,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style scoped lang="sass">
.miners
  &-well, &-danger, &-total
    padding: 6px 16px
    border-radius: 8px
    font-weight: 500
    font-size: 12px
    color: white
  &-well
    background: #78CD5A
  &-danger
    background: #F42A4E
  &-total
    background: #C2C2C2
</style>
